<template>
    <section>
        <div class="row mx-0 my-2 py-3 br-12 align-items-center bg-white">
            <div class="col border-r-gris">
                <div class=" text-general text-center">
                    <p class="f-600 f-30">{{ separadorNumero(info.deuda_total) }}</p>
                    <p class="mb-2">Deuda total</p>
                </div>
            </div>
            <div class="col border-r-gris">
                <div class=" text-general text-center">
                    <p class="f-600 f-30">{{ separadorNumero(info.deuda_vencidas) }}</p>
                    <p class="mb-2">Deuda vencida</p>
                </div>
            </div>
            <div class="col border-r-gris">
                <div class=" text-general text-center">
                    <p class="f-600 f-30">{{ separadorNumero(info.deuda_por_cobrar) }}</p>
                    <p class="mb-2">Deuda por cobrar</p>
                </div>
            </div>
            <div class="col border-r-gris">
                <div class=" text-general text-center">
                    <p class="f-600 f-30">{{ info.cuotas_vencidas }}</p>
                    <p class="mb-2">Cuotas vencidas</p>
                </div>
            </div>
            <div class="col">
                <div class=" text-general text-center">
                    <p class="f-600 f-30">{{ info.proxi_cuotas }}</p>
                    <p class="mb-2">Próximas cuotas</p>
                </div>
            </div>
        </div>
        <div class="row mx-0 mt-3">
            <div class="col-12 px-0">
                <tabla-general :data="dataTable" alto="calc(100vh - 510px)" class-header="text-general f-16 f-600 w-100">
                    <template slot="cabecera-izquierda">
                        <div class="col-auto f-500 text-general f-17">
                            Cobros pendientes
                        </div>
                    </template>
                    <template slot="adicionales-derecha">
                        <el-table-column
                        prop="propietario_nombre"
                        :label="$config.vendedor"
                        class-name="text-center text-center f-16"
                        sortable
                        />
                        <el-table-column
                        prop="valor"
                        label="Valor pedido"
                        class-name="text-center"
                        sortable
                        >
                            <template slot-scope="scope">
                                <span>{{ separadorNumero(scope.row.valor) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="created_at"
                        label="Fecha pedido"
                        class-name="text-center"
                        sortable
                        >
                            <template slot-scope="scope">
                                <span>{{ scope.row.created_at | helper-fecha('DD MMM YYYY') }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="total_debe"
                        label="Debe"
                        class-name="text-center"
                        sortable
                        >
                            <template slot-scope="scope">
                                <span>{{ separadorNumero(scope.row.total_debe) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="fecha"
                        label="Fecha pago"
                        class-name="text-center"
                        sortable
                        >
                            <template slot-scope="scope">
                                <p :class="{'text-general-red': menorAHoy(scope.row.fecha)}" class="f-16">
                                    <span>{{ scope.row.fecha | helper-fecha('DD MMM YYYY') }}</span>
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column v-if="$can('boton_tesoreria_pedidos_registrar_pago')">
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center">
                                    <div class="bg-general3 text-white br-20 px-3 py-1 cr-pointer" @click="AbrirResgistrarPago(scope.row)">
                                        Registrar pago
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
    </section>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import creditos from '~/services/almacen/creditos'
export default {
    data(){
        return {
            dataTable: [],
            dataColumns: [
                { valor: 'id', titulo: 'Pedido', class: 'text-center f-16' },
                { valor: 'propietario_nombre', titulo: 'Vendedor', class: 'text-center f-16' },
                { valor: 'valor', titulo: 'Valor pedido', class: 'text-center f-16' },
                { valor: 'created_at', titulo: 'Fecha pedido', class: 'text-center f-16' },
                { valor: 'total_debe', titulo: 'Debe', class: 'text-center f-16' },
            ],
            info: {}
        }
    },
    computed:{
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
            id_cedis:'cedis/id_cedis',
        }),
    },
    watch:{
        id_cedis(val, oldVal){
            if(oldVal == null) return
            this.listarCuotas()
        },
        id_moneda(val, oldVal){
            if(oldVal == null) return
            this.listarCuotas()
        }
    },
    mounted(){
        this.montar()
    },

    methods: {
        menorAHoy: (fecha) => moment(fecha).isBefore(moment().format('YYYY-MM-DD')),
        async montar(){
            try {
                if(this.id_cedis === null || this.id_moneda === null){
                    setTimeout(async() => {
                        await this.listarCuotas()
                    }, 1000);
                }else{
                    await this.listarCuotas()
                }
            } catch (error){
                console.log(error)
            }
        },

        async listarCuotas(){
            try {
                this.loading = true
                const params = {
                    moneda: this.id_moneda,
                }
                const { data } = await creditos.listarCreditos(params)
                this.dataTable = data.creditos
                /* this.dataTable = data.creditos.map(el => ({
                    ...el,
                    created_at: this.formatearFecha(el.created_at),
                    fecha: this.formatearFecha(el.fecha),
                    valor: this.separadorNumero(el.valor),
                    total_debe: this.separadorNumero(el.total_debe),
                })) */
                this.info = data.info
            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        AbrirResgistrarPago({ id: id_pedido }){
            this.$router.push({name: 'admin.tesoreria.creditos.cobro.ver', params: { id_pedido }})
        },
    }
}
</script>
